/* Layout */
.form-text {
  min-height: 34px;
}

.form-control-danger {
  background-color: #ffdbdb;
}

form label.error, form label.invalid {
  color: red !important;
}
form .form-control.valid, form .form-control:focus.valid, form .Select-control.valid, form .Select-control:focus.valid, form .signature-box.valid, form .signature-box:focus.valid {
  border-color: green;
}
form .form-control.error, form .form-control:focus.error, form .form-control.invalid, form .form-control.invalid:focus, form .Select-control.error, form .Select-control:focus.error, form .Select-control.invalid, form .Select-control.invalid:focus, form .signature-box.error, form .signature-box:focus.error, form .signature-box.invalid, form .signature-box.invalid:focus {
  border-color: red !important;
}
form .callout-warning label.error, form .callout-warning label.invalid,
form .callout-danger label.error,
form .callout-danger label.invalid {
  color: yellow !important;
}
form .form-block > h3 {
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 0;
  color: #fff;
  padding: 4px 7px;
  background-color: #3d84ad;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
form .form-block .form-body {
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  padding: 5px 5px 0;
}
form .media {
  margin-top: 0;
}
form .media div:nth-child(2) {
  padding-left: 15px;
}
form .media .media-left .fa {
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  color: #d6d6d6;
  font-size: 50px;
  padding: 30px 30px;
}
form .media .media-left img {
  width: 107px;
  border-radius: 10px;
}
form .no-image-crop {
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  color: #a9a9a9;
  font-size: 60px;
  height: 100px;
  padding-top: 35px;
  padding-bottom: 109px;
  text-align: center;
  width: 100%;
}

.signature-box {
  border: 1px solid #737373;
  touch-action: none !important;
}
.signature-box .clear-signature-btn {
  margin-top: 6px;
  position: absolute;
  right: 22px;
}

.checkbox-custom {
  margin-right: 1em;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.checkbox-custom.radio-checked {
  font-weight: bold;
}
.checkbox-custom input[type=checkbox] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  cursor: pointer;
  font: inherit;
  margin-right: 5px;
  color: #c7c7c7;
  width: 24px;
  height: 24px;
  border: 1px solid currentColor;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}
.checkbox-custom input[type=checkbox]::before {
  -webkit-text-fill-color: rgb(255, 255, 255);
  color: white;
  -webkit-opacity: 1;
  opacity: 1;
  font-family: "FontAwesome";
  content: "\f00c";
  text-align: center;
  padding-top: 3px;
  font-size: 13px;
  width: 24px;
  height: 24px;
  transform: scale(0);
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #2489c5;
}
.checkbox-custom input[type=checkbox]:checked::before {
  transform: scale(1);
}
.checkbox-custom input[type=checkbox]:focus, .checkbox-custom input[type=checkbox]:hover {
  outline: none;
  border: 2px solid #2489c5;
}

body {
  font-family: Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42857143;
}

body,
#root > .wrapper {
  height: auto;
}

body {
  background-color: #ecf0f5;
}

a {
  cursor: pointer;
}

.text-italic {
  font-style: italic;
}

h3.title {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  color: #666;
  font-weight: 600;
  margin-bottom: 15px;
  padding-bottom: 3px;
}

h5.title-obs {
  color: #636363;
  font-style: italic;
  font-size: 1.7rem;
  margin-bottom: 20px;
}

/* Absolute Center Spinner */
.full-overlay {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 1030;
}

.text-lg {
  font-size: 6em;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-pointer:hover {
  filter: brightness(110%);
}

.cursor-search {
  cursor: zoom-in;
}

.screen-height {
  height: 70vh;
}

.Select {
  z-index: 3;
}
.Select .Select-control {
  border-radius: 0 !important;
}
.Select .Select-input,
.Select .Select-control {
  height: 32px !important;
}
.Select.is-open {
  z-index: 100 !important;
}

.vehicle-details table tr td {
  width: 50%;
}
.vehicle-details table tr > td {
  max-width: 200px;
}

.text-right {
  text-align: right;
}

.text-middle {
  vertical-align: middle !important;
}

table .text-right {
  text-align: right;
}
table.text-middle th,
table.text-middle td {
  vertical-align: middle !important;
}
table td.cursor-pointer:hover {
  color: rgb(60, 141, 188);
  text-decoration: underline;
}
table tr.deleted td:not(.ignore-deleted), table tr.deleted th:not(.ignore-deleted) {
  text-decoration: line-through;
}
table th.table-action-button, table td.table-action-button {
  cursor: pointer;
  font-size: 20px;
  padding: 3px !important;
  text-align: center;
  vertical-align: middle !important;
}
table th.table-action-button.red, table td.table-action-button.red {
  color: #bf493a;
}
table th.table-action-button.green, table td.table-action-button.green {
  color: #2d9063;
}
table th.table-action-button.orange, table td.table-action-button.orange {
  color: #f39c12;
}
table th.table-action-button.blue, table td.table-action-button.blue {
  color: #13aed4;
}
table th.table-action-button:hover, table td.table-action-button:hover {
  filter: brightness(80%);
}

table.no-border td {
  border: none;
}

table.no-margin-bottom {
  margin-bottom: 0;
}

.app-version {
  bottom: 5px;
  color: #888888;
  left: 10px;
  position: absolute;
  z-index: 810;
}

.main-footer {
  height: 35px !important;
  padding: 8px 10px !important;
  font-size: 12px !important;
}

@media print {
  canvas {
    width: 100% !important;
  }
  img {
    max-width: none !important;
    max-height: none !important;
  }
  .google-map {
    width: 100vh;
  }
  .graph-box {
    width: 100%;
    page-break-inside: avoid !important;
  }
  [class*=col-lg],
[class*=col-md],
[class*=col-sm] {
    width: 100%;
  }
  .toast,
.btn,
.dataTables_length,
.dataTables_filter,
.dataTables_paginate,
.dataTables_info {
    display: none !important;
  }
  .hidden-xs {
    display: block !important;
  }
}
.profile-user-img {
  height: 100px;
}

.action-btn {
  padding: 6px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.action-btn.action-view-btn {
  color: #3c8dbc;
  font-size: 22px;
}
.action-btn.action-view-btn:hover {
  color: #397292;
}

.remove-btn {
  color: #da1010;
  cursor: pointer;
}
.remove-btn:hover {
  color: #b31616;
}
.remove-btn:active {
  color: #961717;
}

.edit-btn {
  color: #ce8a0c;
  cursor: pointer;
}
.edit-btn:hover {
  color: #a77318;
}
.edit-btn:active {
  color: #8e6215;
}

.btn-rounded {
  border-radius: 50% !important;
}

p {
  white-space: pre-wrap;
}

.content-wrapper.full-width,
.main-footer.full-width {
  margin-left: 0 !important;
}

.sidebar-mini.sidebar-collapse .content-wrapper.full-width,
.sidebar-mini.sidebar-collapse .main-footer.full-width {
  margin-left: 0 !important;
}

.blink:not(:hover) {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}
.loading-page {
  color: #0d4a6d;
  text-align: center;
  background-color: #f0f3f7;
  height: 100vh;
  overflow: hidden;
  padding-top: 20vh;
}
.loading-page .loader {
  width: 80px;
  height: 80px;
  border-width: 7px;
}
.loading-page .logo {
  display: inline;
  max-height: 130px;
}
.loading-page i {
  font-size: 23px;
  display: block;
}

.error-page h1 {
  font-size: 50px;
  margin-top: 5px;
}
.error-page h3 {
  font-size: 20px;
  font-weight: bold;
}
.error-page .message-content {
  font-size: 17px;
}
.error-page .logo {
  display: inline;
  max-height: 130px;
}

@media only screen and (max-width: 768px) {
  .error-page h1 {
    font-size: 40px;
    margin-top: 5px;
  }
  .error-page img {
    max-height: 120px;
  }
  .error-page .message-content {
    font-size: 15px;
    padding: 10px;
  }
}
.text-red {
  color: #db2828;
}

.text-blue {
  color: #2185d0;
}

.text-green {
  color: #008000;
}

.text-orange {
  color: #b64900 !important;
}

.background-red {
  background: rgba(219, 40, 40, 0.07) !important;
}

.background-blue {
  background: rgba(33, 133, 208, 0.07) !important;
}

.background-green {
  background: rgba(0, 128, 0, 0.07) !important;
}

.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-blue .cursor-pointer:hover,
.text-green .cursor-pointer:hover,
.text-red .cursor-pointer:hover {
  filter: brightness(0.7);
}

select > option:disabled {
  color: #b3b3b3;
}

.Select-value {
  font-weight: normal;
}

.Select-option.text-danger {
  color: red;
}
.Select-option.text-warning {
  color: orange;
}
.Select-option.text-success {
  color: green;
}

.sortable-list .sortable-chosen .input-group {
  filter: brightness(0.85);
}

.sortable-list-index {
  padding: 21px 20px;
  background-color: #13aed4;
  border-radius: 5px;
  text-align: center;
  color: white;
  font-weight: bold;
}

.home-link-container .badge {
  position: absolute;
  right: 20px;
  top: 5px;
  background: #e3e61c;
  color: #000;
}

.flex {
  display: flex;
}
.flex.flex-row {
  flex-direction: row;
}
.flex.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.react-tooltip {
  max-width: 300px;
  text-align: center;
  font-size: 15px !important;
  font-weight: normal !important;
  opacity: 1 !important;
}

canvas {
  touch-action: inherit !important;
}

.chart-container {
  height: 30vh;
  max-height: 250px;
}

.main-sidebar .treeview > .treeview-menu {
  padding-left: 0 !important;
}

.main-sidebar a.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.main-sidebar a.no-nested-item {
  background-color: #080b0c !important;
}
.main-sidebar .treeview ul li a {
  padding-left: 35px !important;
}
.main-sidebar .treeview-menu a.disabled {
  color: #4f575a;
}
.main-sidebar .treeview-menu .treeview-menu {
  padding-left: 15px !important;
  background-color: rgb(8, 11, 12) !important;
}
.main-sidebar .treeview-menu .treeview-menu a.disabled {
  color: #2e3335;
}

/* Absolute Center Spinner */
.overlay {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.overlay .loader {
  margin: -45px -25px;
}

/* Transparent Overlay */
.overlay:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.overlay:not(:required) {
  /* hide "overlay..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loader {
  width: 55px;
  height: 55px;
  margin: 0 auto;
  border: 5px solid #2e6f94;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 800ms infinite linear;
  -o-animation: cssload-spin 800ms infinite linear;
  -ms-animation: cssload-spin 800ms infinite linear;
  -webkit-animation: cssload-spin 800ms infinite linear;
  -moz-animation: cssload-spin 800ms infinite linear;
}
.loader.loader-white {
  border-color: #ffffff;
  border-left-color: transparent;
  border-right-color: transparent;
}
.loader.loader-sm {
  width: 18px;
  height: 18px;
  border-width: 3px;
}
.loader.loader-large {
  width: 80px;
  height: 80px;
  border-width: 6px;
}

.btn .loader {
  width: 14px;
  height: 14px;
  border-width: 2px;
  display: inline-flex;
  margin: -2px 2px;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.map-control {
  margin: 10px;
  width: 90%;
  max-width: 300px;
  box-shadow: 4px 3px 3px rgba(0, 0, 0, 0.5);
}

.google-marker-label {
  margin-top: 10px;
  background-color: #585858;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: white !important;
  font-size: 12px !important;
  padding: 5px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
}

.mvi-main {
  overflow: hidden;
  word-wrap: break-word;
  cursor: text;
  background-color: #fff;
  border: 1px solid #d2d6de;
  height: 100%;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.mvi-main.mvi-focused {
  border-color: #3c8dbc;
  outline: 0;
}
.mvi-main.error {
  border-color: #ff0000;
}
.mvi-main input {
  border: 0;
  height: 32px;
  float: left;
  padding: 0 10px;
}
.mvi-main input:read-only {
  cursor: text;
}
.mvi-main input::-webkit-calendar-picker-indicator {
  display: none;
}
.mvi-main input:focus {
  outline: none;
}
.mvi-main ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mvi-main ul * {
  display: inline-block;
  float: left;
}
.mvi-main ul li.mvi-item {
  margin: 2px 2px 0 2px;
  /***************
  **** COLORS ****
  ***************/
  /* Default */
  /* Primary */
  /* Info */
  /* Warning */
  /* Danger */
}
.mvi-main ul li.mvi-item div {
  color: #ffffff;
  padding: 4px 10px;
}
.mvi-main ul li.mvi-item div.mvi-value {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  margin-left: 3px;
}
.mvi-main ul li.mvi-item div.mvi-remove {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding-top: 2px;
}
.mvi-main ul li.mvi-item div.mvi-remove:after {
  content: "x";
}
.mvi-main ul li.mvi-item.mvi-default .mvi-value {
  color: #555555;
  background-color: #eeeeee;
}
.mvi-main ul li.mvi-item.mvi-default .mvi-remove {
  color: #555555;
  background-color: #e4e4e4;
}
.mvi-main ul li.mvi-item.mvi-default .mvi-remove:hover {
  background-color: #d5d5d5;
}
.mvi-main ul li.mvi-item.mvi-default .mvi-remove:active {
  background-color: #c8c8c8;
}
.mvi-main ul li.mvi-item.mvi-primary .mvi-value {
  background-color: #337ab7;
}
.mvi-main ul li.mvi-item.mvi-primary .mvi-remove {
  background-color: #2f6fa7;
}
.mvi-main ul li.mvi-item.mvi-primary .mvi-remove:hover {
  background-color: #285f8f;
}
.mvi-main ul li.mvi-item.mvi-primary .mvi-remove:active {
  background-color: #22527b;
}
.mvi-main ul li.mvi-item.mvi-info .mvi-value {
  background-color: #5bc0de;
}
.mvi-main ul li.mvi-item.mvi-info .mvi-remove {
  background-color: #4ab9db;
}
.mvi-main ul li.mvi-item.mvi-info .mvi-remove:hover {
  background-color: #31b0d5;
}
.mvi-main ul li.mvi-item.mvi-info .mvi-remove:active {
  background-color: #28a1c5;
}
.mvi-main ul li.mvi-item.mvi-warning .mvi-value {
  background-color: #f0ad4e;
}
.mvi-main ul li.mvi-item.mvi-warning .mvi-remove {
  background-color: #eea43b;
}
.mvi-main ul li.mvi-item.mvi-warning .mvi-remove:hover {
  background-color: #ec971f;
}
.mvi-main ul li.mvi-item.mvi-warning .mvi-remove:active {
  background-color: #df8a13;
}
.mvi-main ul li.mvi-item.mvi-danger .mvi-value {
  background-color: #d9534f;
}
.mvi-main ul li.mvi-item.mvi-danger .mvi-remove {
  background-color: #d5433e;
}
.mvi-main ul li.mvi-item.mvi-danger .mvi-remove:hover {
  background-color: #c9302c;
}
.mvi-main ul li.mvi-item.mvi-danger .mvi-remove:active {
  background-color: #b52b27;
}

.form-radio-flex {
  display: flex;
}
.form-radio-flex .radio-list {
  margin-left: 10px;
}

.form-group > .radio-list {
  margin-top: 5px;
}

.radio-list {
  display: flex;
  text-align: center;
}
.radio-list.radio-list-vertical {
  display: grid;
  text-align: left;
}

.radio-custom {
  margin-right: 1em;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}
.radio-custom.radio-checked {
  font-weight: bold;
}
.radio-custom input[type=radio] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  cursor: pointer;
  font: inherit;
  margin-right: 3px;
  color: #c7c7c7;
  width: 24px;
  height: 24px;
  border: 1px solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}
.radio-custom input[type=radio]::before {
  -webkit-text-fill-color: rgb(255, 255, 255);
  color: white;
  -webkit-opacity: 1;
  opacity: 1;
  font-family: "FontAwesome";
  content: "\f00c";
  text-align: center;
  padding-top: 2px;
  font-size: 14px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #2489c5;
}
.radio-custom input[type=radio]:checked::before {
  transform: scale(1);
}
.radio-custom input[type=radio]:focus, .radio-custom input[type=radio]:hover {
  outline: none;
  border: 2px solid #2489c5;
}

.nav-pills li.active a, .nav-pills li.active a:hover {
  color: white;
  background-color: #337ab7;
}
.nav-pills li a:hover {
  filter: brightness(95%);
}

.wizard-steps {
  padding: 0;
}
.wizard-steps li {
  background: #bdbdbd;
  cursor: pointer;
  list-style-type: none;
  margin-bottom: 2px;
  opacity: 0.8;
  padding: 20px 20px 20px 70px !important;
  width: 100%;
}
.wizard-steps li > a {
  color: #565656;
  font-size: 1.8rem;
  font-weight: bold;
}
.wizard-steps li > a .fa {
  position: absolute;
  left: 30px;
  margin-top: -7px;
  text-align: center;
  width: 40px;
}
.wizard-steps li > a:after {
  font-family: "FontAwesome";
  position: absolute;
}
.wizard-steps li.current {
  background-color: #5a9ac7;
}
.wizard-steps li.completed {
  background-color: #66b166;
}
.wizard-steps li.completed:not(.active) a:after {
  content: "\f00c";
  font-size: 20px;
  right: 28px;
}
.wizard-steps li.skipped {
  background-color: #bd843b;
}
.wizard-steps li.skipped:not(.active) a:after {
  content: "\f103";
  font-size: 20px;
  right: 28px;
}
.wizard-steps li.completed a, .wizard-steps li.current a, .wizard-steps li.invalid a, .wizard-steps li.skipped a {
  color: rgba(255, 255, 255, 0.85);
}
.wizard-steps li:hover {
  opacity: 1;
}
.wizard-steps li.active {
  opacity: 1;
}
.wizard-steps li.active a:after {
  content: "\f105";
  font-size: 35px;
  margin-top: -12px;
  right: 32px;
}
.wizard-steps li.invalid {
  background-color: #b54b4b;
}
.wizard-steps li:not(.completed, .current):hover {
  background-color: #d8d8d8;
}

.wizard-content {
  display: none;
}
.wizard-content.active {
  display: block;
}
.wizard-content .box-body {
  overflow: auto;
}

.wizard-buttons {
  padding: 0;
  text-align: right;
}
.wizard-buttons li {
  display: inline-block;
  list-style-type: none;
  padding-right: 5px;
  padding-left: 5px;
}
.wizard-buttons li .btn {
  width: 100px;
}

.wizard-inline .wizard-steps {
  display: flex;
}
.wizard-inline .wizard-steps li {
  margin: 2px;
  padding: 8px 0 !important;
  text-align: center;
}
.wizard-inline .wizard-steps li.active {
  margin: -2px 0 !important;
  padding: 12px 2px !important;
}
.wizard-inline .wizard-steps li > a .fa {
  position: initial;
  font-size: 1.3em;
  width: 100%;
  margin: 0;
}
.wizard-inline .wizard-steps li a:after {
  content: "" !important;
}

@media (max-width: 991px) {
  .wizard-steps {
    display: flex;
  }
  .wizard-steps li {
    margin: 2px;
    padding: 8px 0 !important;
    text-align: center;
  }
  .wizard-steps li.active {
    margin: -2px 0 !important;
    padding: 12px 2px !important;
  }
  .wizard-steps li > a .fa {
    position: initial;
    font-size: 1.3em;
    width: 100%;
    margin: 0;
  }
  .wizard-steps li a:after {
    content: "" !important;
  }
  .wizard-content .box-body {
    max-height: 100%;
  }
}
/* Components */
.time-windows table .table-action-button {
  width: 80px;
}
.time-windows table thead tr th, .time-windows table thead tr td {
  border-bottom-width: 2px !important;
}
.time-windows table tr .th-week-day {
  width: 50px;
  vertical-align: middle;
}
.time-windows table tr th, .time-windows table tr td {
  border: 1px solid #bdbdbd !important;
}
.time-windows table tr.even {
  background-color: #f4fffc;
}
.time-windows table tr.odd {
  background-color: #f4fcff;
}
.time-windows table tr.saturday {
  background-color: #fffbf1;
}
.time-windows table tr.sunday {
  background-color: #fff6e5;
}
.time-windows table tr:hover {
  filter: brightness(95%);
}

/* 3rd Part Changes */
.box-no-margin-bottom {
  margin-bottom: 0 !important;
}

.box, .nav-tabs-custom, .nav.nav-pills {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4) !important;
}

.box-bordered {
  box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.15) !important;
  border-left: 1px solid #bfbfbf;
}

.content-header > .breadcrumb {
  background: none !important;
  position: absolute !important;
  right: 10px !important;
}
.content-header > .breadcrumb a {
  font-size: 22px;
}

.sidebar-menu li > a {
  padding: 8px 5px 12px 15px;
}
.sidebar-menu > li > .treeview-menu {
  margin: 0 !important;
  background: #080b0c !important;
}

@media (min-width: 768px) {
  .content-header > .breadcrumb {
    top: 8px !important;
  }
}
.navbar-nav > .user-menu div {
  display: inline;
}
.navbar-nav > .user-menu .dropdown-menu {
  box-shadow: -1px 3px 2px rgba(0, 0, 0, 0.7);
  right: 0 !important;
  border: none !important;
}
.navbar-nav > .user-menu .dropdown-menu > li.user-header img {
  z-index: 5;
  height: 90px;
  width: 90px;
  border: 3px solid;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}
.navbar-nav > .user-menu .dropdown-menu > li.user-footer {
  color: #fff;
}
.navbar-nav > .user-menu .dropdown-menu > li.user-footer .btn-primary {
  background-color: #3c8dbc;
  border-color: #367fa9;
}
.navbar-nav > .user-menu .dropdown-menu > li.user-footer .btn-danger {
  background-color: #a53f32;
  border-color: #bf493a;
}

/*
	TIMELINE
*/
.timeline > li > .timeline-item {
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: 0 !important;
  border-left: 2px solid #848484 !important;
}
.timeline > li > .timeline-item > .timeline-header {
  border: none !important;
}
.timeline > li > .timeline-item > .timeline-header .title {
  color: #2c76a0 !important;
  font-weight: bold;
}
.timeline > li > .timeline-item > .timeline-body {
  padding: 0 10px !important;
}
.timeline > li > .timeline-item > .time {
  font-size: 16px !important;
  font-weight: bold;
  float: none !important;
}

.main-sidebar, .left-side {
  min-height: 0%;
}

@media (max-width: 767px) {
  .wrapper {
    height: auto;
  }
  .wrapper > .main-content, .wrapper .main-sidebar {
    padding-top: 50px !important;
  }
}
header.main-header .logo {
  border-right: 1px solid #222d32;
}
header.main-header .logo span {
  font-family: "Source Sans Pro";
}
header.main-header .navbar, header.main-header .logo {
  background: #386eb9; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #386eb9, #0ea2c4); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #457cc9, #0ea2c4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.main-sidebar {
  background: #222d32; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2d3c42, #222d32); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #2d3c42, #222d32); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.main-sidebar .sidebar-menu > li.header {
  font-size: 14px;
  color: white;
  background: #222d32; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #2d3c42, #222d32); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #1b2124, #111518); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.callout {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
}
.callout h4 {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.callout.callout-success {
  border-color: #05744d !important;
  background: #1D976C; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1D976C, #29b35b); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1D976C, #29b35b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.callout.callout-danger {
  border-color: #9e1509 !important;
  background: #e73827; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #e73827, #e73827); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e73827, #f85032); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.callout.callout-info {
  border-color: #09738d !important;
  background: #1d8fac; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #17829c, #6dd5ed); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #17829c, #53b9cf); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.callout.callout-warning {
  border-color: #b1300c !important;
  background: #e75128; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #e75128, #ff720b); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #e75128, #ff720b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.btn {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}
.btn:hover {
  filter: brightness(0.9);
}
.btn.btn-success {
  background: #1D976C; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #1D976C, #29b35b); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #1D976C, #29b35b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn.btn-danger {
  background: #d83a2b; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #d83a2b, #ff7259); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #d83a2b, #ff7259); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn.btn-info {
  background: #17829c; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #17829c, #53b9cf); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #17829c, #53b9cf); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn.btn-warning {
  background: #F7971E; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #F7971E, #f5c800); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #F7971E, #f5c800); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn.btn-primary {
  background: #1961a5; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #1961a5, #509ae0); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #1961a5, #509ae0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn.btn-default {
  color: #525252;
  text-shadow: none;
  border: 1px solid #cccccc !important;
  background: #cacaca; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #cacaca, #ebebeb); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #dddddd, #eeeeee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.text-center {
  text-align: center !important;
}

.no-padding > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

[class*=col-].no-padding-left {
  padding-left: 0;
}

[class*=col-].no-padding-right {
  padding-right: 0;
}

.left-inner-addon,
.right-inner-addon {
  position: relative;
}
.left-inner-addon i,
.right-inner-addon i {
  z-index: 4;
  position: absolute;
  padding: 10px 13px;
  pointer-events: none;
  color: #ababab;
}

.left-inner-addon input {
  padding-left: 30px;
}

.right-inner-addon input {
  padding-right: 30px;
}
.right-inner-addon i {
  right: 0px;
}

.input-group .input-group-addon {
  background-color: #eee !important;
}
.input-group .input-group-addon.separator {
  padding: 3px;
  border-left: none;
  border-right: none;
}
.input-group .react-select-container {
  display: table-cell;
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

/*
  BUTTONS
*/
.btn.btn-success {
  background-color: #2d9063;
  border-color: #2d9063;
}
.btn.btn-success:active {
  background-color: #216747;
}
.btn.btn-success:hover {
  background-color: #277d56;
}
.btn.btn-info {
  background-color: #13aed4;
  border-color: #13aed4;
}
.btn.btn-info:active {
  background-color: #25859c;
}
.btn.btn-info:hover {
  background-color: #1ea1c1;
}
.btn.btn-danger {
  background-color: #bf493a;
  border-color: #bf493a;
}
.btn.btn-danger:active {
  background-color: #823026;
}
.btn.btn-danger:hover {
  background-color: #a53f32;
}
.btn.btn-warning:hover {
  background-color: #d4860a;
}

/*
  END BUTTONS
*/
/*
  CALLOUT
*/
div.callout {
  padding: 10px;
}
div.callout.callout-danger {
  background-color: #bf493a !important;
  border-color: #9c291b;
}

/*
  END CALLOUT
*/
/*
  MODAL
*/
.modal .modal-header button.close {
  margin: 0;
  font-size: 38px;
  position: absolute;
  right: 18px;
  top: 5px;
  color: #bf4a3b;
  opacity: 0.7;
}
.modal .modal-header button.close:hover {
  opacity: 1;
}
.modal .modal-lg {
  width: auto;
  margin: 10px;
  max-width: 1100px;
}
@media (min-width: 1110px) {
  .modal .modal-lg {
    margin: 10px auto;
  }
}

/*
  END MODAL
*/
.aligned-row {
  display: flex;
  flex-flow: row wrap;
}
.aligned-row::before {
  display: block;
}

.row .row {
  margin-right: -10px;
  margin-left: -10px;
}

/*
  DATATABLES
*/
div.dataTables_wrapper .right > div {
  float: right;
}
div.dataTables_wrapper .center > div {
  text-align: center;
}
div.dataTables_wrapper .btn-group > .btn:not(:first-child) {
  margin-left: 5px;
}

@media only screen and (max-width: 768px) {
  div.dataTables_wrapper .left, div.dataTables_wrapper .right {
    text-align: center;
    padding: 10px 0;
  }
  div.dataTables_wrapper .left > div, div.dataTables_wrapper .right > div {
    float: inherit;
  }
}
.pac-container {
  z-index: 1051 !important;
}

.btn.align-middle {
  margin-top: 27px;
}

.nav.nav-pills.nav-justified {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px) and (max-width: 1699px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl {
  display: none !important;
}

@media (min-width: 1700px) {
  .container {
    width: 1570px;
  }
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .visible-xl {
    display: block !important;
  }
  table.visible-xl {
    display: table;
  }
  tr.visible-xl {
    display: table-row !important;
  }
  th.visible-xl, td.visible-xl {
    display: table-cell !important;
  }
  .visible-xl-block {
    display: block !important;
  }
  .visible-xl-inline {
    display: inline !important;
  }
  .visible-xl-inline-block {
    display: inline-block !important;
  }
  .hidden-xl {
    display: none !important;
  }
}
@media print {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  body {
    overflow: auto !important;
  }
  body.modal-open #root {
    display: none !important;
  }
  button, .nav, .modal-backdrop, .modal-footer,
.no-print, .main-footer {
    display: none;
  }
  .tab-pane {
    opacity: 1 !important;
    display: block !important;
  }
  .modal {
    position: relative;
    background: white !important;
    padding-left: 0;
  }
  .modal > div {
    margin: 0;
  }
  .modal > div .modal-content .modal-header, .modal > div .modal-content .modal-body {
    padding: 0;
  }
}
.nav-tabs {
  margin-bottom: 10px;
}

.table td, .table th {
  vertical-align: middle !important;
}
.table.table-black-border td, .table.table-black-border th {
  border: 1px solid rgb(161, 161, 161) !important;
}

.page-header {
  padding-bottom: 0px;
  border-bottom: 2px solid #c7c7c7;
}
.page-header h1 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 0;
}

.badge.status {
  font-size: 16px;
  margin-top: -2px;
}
.badge.status.danger {
  background-color: rgba(255, 0, 0, 0.15);
}
.badge.status.primary {
  background-color: rgba(33, 133, 208, 0.2);
}
.badge.status.success {
  background-color: rgba(0, 160, 0, 0.12);
}
.badge.status.warning {
  background-color: rgba(255, 188, 0, 0.2);
}
.badge.danger {
  background-color: rgb(255, 0, 0);
}
.badge.primary {
  background-color: rgb(33, 133, 208);
}
.badge.success {
  background-color: rgb(0, 160, 0);
}
.badge.warning {
  background-color: rgb(255, 188, 0);
}

/*
*   CSS file with Bootstrap grid classes for screens bigger than 1600px. Just add this file after the Bootstrap CSS file and you will be able to juse col-xl, col-xl-push, hidden-xl, etc.
*
*   Author: Marc van Nieuwenhuijzen
*   Company: WebVakman
*   Site: WebVakman.nl
*
*/
@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl {
  display: none !important;
}

@media (min-width: 1600px) {
  .container {
    width: 1570px;
  }
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .visible-xl {
    display: block !important;
  }
  table.visible-xl {
    display: table;
  }
  tr.visible-xl {
    display: table-row !important;
  }
  th.visible-xl, td.visible-xl {
    display: table-cell !important;
  }
  .visible-xl-block {
    display: block !important;
  }
  .visible-xl-inline {
    display: inline !important;
  }
  .visible-xl-inline-block {
    display: inline-block !important;
  }
  .hidden-xl {
    display: none !important;
  }
}
.checkbox {
  z-index: 1;
  margin-top: 5px;
  position: initial;
}
.checkbox label {
  cursor: pointer;
  padding-left: 0;
  padding-right: 10px;
}
.checkbox label > div {
  margin-right: 5px;
}
.checkbox.no-margin {
  margin: 0 !important;
}

.noUi-horizontal {
  margin-top: 8px !important;
}

.noUi-margin {
  margin-bottom: 45px !important;
}

.noUi-handle {
  cursor: move;
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important;
}

.noUi-active {
  cursor: move;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important;
}

.ReactTable {
  background: #ffffff;
}
.ReactTable .rt-thead div {
  text-align: left;
  font-weight: bold;
}
.ReactTable .rt-thead .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px rgba(0, 0, 0, 0.3) !important;
}
.ReactTable .rt-tbody .rt-tr.line-through {
  text-decoration: line-through;
}
.ReactTable .rt-tbody .rt-tr.-even {
  background-color: rgb(250, 250, 250);
}
.ReactTable .rt-tbody .rt-tr .orange, .ReactTable .rt-tbody .rt-tr.warning {
  background: #fff5e6 !important;
}
.ReactTable .rt-tbody .rt-tr .red, .ReactTable .rt-tbody .rt-tr.danger {
  background: #ffe8e8 !important;
}
.ReactTable .rt-tbody .rt-tr .blue, .ReactTable .rt-tbody .rt-tr.primary {
  background: #e2f5ff !important;
}
.ReactTable .rt-tbody .rt-tr .grey, .ReactTable .rt-tbody .rt-tr.default {
  background: #e4e4e4 !important;
}
.ReactTable .rt-tbody .rt-tr .green, .ReactTable .rt-tbody .rt-tr.success {
  background: #ebf9eb !important;
}
.ReactTable .rt-tbody .rt-tr:hover {
  cursor: pointer;
  background-color: #e1f0ff;
  box-shadow: inset 0 2px 8px rgba(0, 128, 255, 0.12), inset 0 -2px 8px rgba(0, 128, 255, 0.12);
}
.ReactTable .rt-tbody .rt-tr:hover .rt-td {
  font-weight: bold;
}
.ReactTable .rt-tbody .rt-tr:hover .rt-td.bg-danger {
  filter: brightness(0.95);
  box-shadow: inset 0 2px 15px rgba(255, 0, 0, 0.1), inset 0 -2px 15px rgba(255, 0, 0, 0.1);
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.ReactTable .-pagination .-btn:not(:disabled) {
  color: #fff;
  background: #367fa9;
}
.ReactTable .-pagination .-btn:not(:disabled):hover {
  background: #286090 !important;
}
.ReactTable .orange {
  background: #fff5e6 !important;
}
.ReactTable .red {
  background: #ffe8e8 !important;
}
.ReactTable .blue {
  background: #e2f5ff !important;
}
.ReactTable .grey {
  background: #e4e4e4 !important;
}
.ReactTable .green {
  background: #ebf9eb !important;
}

.daterangepicker.hide-input .daterangepicker_input {
  display: none !important;
}

@media only screen and (max-width: 400px) {
  .daterangepicker {
    width: 96vw;
  }
  .daterangepicker .calendar {
    max-width: none;
  }
  .daterangepicker .calendar.right {
    display: none !important;
  }
}

