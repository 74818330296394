.full-map {
  /** 
  	INPUT FILTERS
  */
  /** 
  	SETTINGS BUTTONS
  */
  /** 
  	RIGHT MENU
  */
  /** 
  	INFO WINDOW
  */
}
.full-map .panel-body {
  padding: 5px 10px;
}
.full-map .info-box-main {
  z-index: 1;
  position: absolute;
  left: 10px !important;
  bottom: 5px !important;
  width: 220px;
  font-size: 11px;
}
.full-map .info-box-main table tr th {
  width: 85px;
  padding: 1px 0;
}
.full-map .info-box-main .loader {
  position: absolute;
  bottom: 0;
  right: -70px;
}
.full-map .input-filter-box {
  width: 100vw;
  max-width: 340px;
  margin: 10px;
  z-index: 1 !important;
}
.full-map .input-filter-box .search-input, .full-map .input-filter-box .Select {
  width: 75%;
  font-size: 13px;
}
.full-map .input-filter-box .search-type-box {
  position: absolute;
  height: 34px;
  padding: 8px 0;
  right: 0;
  top: 0px;
  width: 25%;
  background: #00c0ef;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #d2d6de;
  border-bottom: 1px solid #d2d6de;
}
.full-map .input-filter-box .search-type-box .fa {
  font-size: 16px;
  margin-left: 17px;
  color: white;
  opacity: 0.55;
  cursor: pointer;
}
.full-map .input-filter-box .search-type-box .fa.active, .full-map .input-filter-box .search-type-box .fa:hover {
  opacity: 1;
}
.full-map .settings-buttons-box {
  margin: 10px;
}
.full-map .settings-buttons-box button {
  color: #5a5a5a;
  margin-left: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  background-color: white;
  font-size: 23px;
  padding: 5px 8px;
  border: none;
  border-radius: 2px;
}
.full-map .settings-buttons-box button.fade {
  opacity: 0.4;
}
.full-map .settings-buttons-box button:hover {
  opacity: 1;
  color: #000;
  background-color: #ebebeb;
}
.full-map .right-menu-box {
  top: 55px !important;
  right: 10px !important;
  width: 87vw;
  max-width: 320px;
}
.full-map .right-menu-box .form-group > label {
  font-size: 13px;
}
.full-map .right-menu-box .form-group > label .fa {
  font-size: 15px;
}
.full-map .info-window-box {
  margin-left: 10px;
}
.full-map .info-window-box .info-window {
  width: 94vw;
  max-width: 340px;
}
.full-map .info-window-box .info-window .close-button {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}
.full-map .info-window-box .info-window .close-button:hover {
  color: rgb(175, 0, 0);
}
.full-map .info-window-box .info-window .tab-content {
  padding: 0 5px;
}
.full-map .info-window-box .info-window .tab-content .tab-pane {
  height: 28vh;
  overflow: auto;
}
.full-map .info-window-box .info-window .tab-content table {
  font-size: 12px;
}
.full-map .info-window-box .info-window .tab-content table th {
  width: 100px;
}
.full-map .info-window-box .info-window .tab-content table td, .full-map .info-window-box .info-window .tab-content table th {
  padding: 3px;
}
.full-map .info-window-box .info-window .input-sensors {
  width: 80px;
  padding-left: 3px;
  height: 20px;
  font-size: 13px;
}
.full-map .info-window-box .info-window .buttons-box {
  position: absolute;
  bottom: -17px;
  width: 100%;
}
.full-map .info-window-box .info-window .buttons-box .btn {
  box-shadow: 2px 3px 0px rgba(0, 0, 0, 0.7);
  font-weight: bold;
}
.full-map .info-window-box .info-window .buttons-box .btn.btn-default {
  background-color: #ffffff;
}
.full-map .info-window-box .info-window .buttons-box .btn.btn-default:hover {
  background-color: #f1f1f1;
}
.full-map .vehicle-driver-details {
  padding: 5px 10px;
  margin-top: 10px;
  max-width: 90%;
  font-size: 13px;
}
.full-map .vehicle-driver-details .driver-img {
  height: 50px;
}
@media screen and (max-width: 767px) {
  .full-map .panel-body {
    padding: 8px 10px;
  }
  .full-map .info-box-main {
    width: 185px;
    font-size: 10px;
  }
  .full-map .info-box-main table tr th {
    width: 70px;
  }
  .full-map .input-filter-box {
    max-width: 291px;
  }
  .full-map .input-filter-box .search-type-box .fa {
    margin-left: 13px;
  }
}
@media screen and (max-width: 400px) {
  .full-map .input-filter-box {
    max-width: 250px;
  }
  .full-map .input-filter-box .search-input, .full-map .input-filter-box .Select {
    width: 70%;
  }
  .full-map .input-filter-box .search-type-box {
    width: 30%;
  }
}
@media screen and (max-width: 358px) {
  .full-map .input-filter-box {
    max-width: 230px;
  }
  .full-map .input-filter-box .search-input, .full-map .input-filter-box .Select {
    width: 65%;
  }
  .full-map .input-filter-box .search-type-box {
    width: 35%;
  }
  .full-map .input-filter-box .search-type-box .fa {
    margin-left: 16px;
  }
  .full-map .settings-buttons-box button {
    margin-left: 5px;
    font-size: 20px;
    padding: 3px 6px;
  }
  .full-map .right-menu-box {
    top: 50px !important;
  }
  .full-map .info-window-box .info-window .tab-content .tab-pane {
    height: 15vh;
  }
}

