#pallet-load-panel .panel-row-container {
  position: relative;
  border-radius: 5px;
  box-shadow: 1px 4px 3px rgba(0, 0, 0, 0.5);
  margin-top: 12px;
}
#pallet-load-panel .panel-row-container.bg-red {
  background-color: #614040 !important;
}
#pallet-load-panel .panel-row-container.bg-yellow {
  background-color: #6f5a2d !important;
}
#pallet-load-panel .panel-row-container.bg-blue {
  background-color: #303b52 !important;
}
#pallet-load-panel .panel-row-container.bg-green {
  background-color: #406142 !important;
}
#pallet-load-panel .panel-row-container .panel-row {
  z-index: 10;
  padding: 10px;
}
#pallet-load-panel .panel-row-container .panel-row span:not(.status-icon) {
  font-weight: bold;
  font-size: 28px;
  margin: 0;
  z-index: 1;
  vertical-align: middle;
}
#pallet-load-panel .panel-row-container .panel-row .status-icon {
  position: absolute;
  font-size: 45px;
  color: black;
  right: 10px;
  top: 7px;
  opacity: 0.4;
  text-shadow: none;
  z-index: 3;
}
#pallet-load-panel .panel-row-container.small .panel-row {
  padding: 5px 10px;
}
#pallet-load-panel .panel-row-container.small .panel-row span:not(.status-icon) {
  font-size: 19px;
}
#pallet-load-panel .panel-row-container.small .panel-row .status-icon {
  font-size: 35px;
}
#pallet-load-panel .loads-panel-body .panel-row-container {
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
}
#pallet-load-panel .loads-panel-header {
  background-color: #292929;
  color: white;
}
#pallet-load-panel tbody td {
  font-weight: bold;
  padding: 20px 15px;
  font-size: 40px;
  border-top-width: 10px;
}
#pallet-load-panel .progress-bar {
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  height: 100%;
}
#pallet-load-panel .divider {
  margin-top: 10px;
  font-size: 50px;
  text-shadow: none !important;
  width: 100%;
  text-align: center;
  color: green;
}
#pallet-load-panel .callout {
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
  padding: 20px;
}
#pallet-load-panel .callout h4 {
  font-size: 25px;
  margin: 0;
}

