.checkout-box {
    box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.35);

    &.blur {
        -webkit-filter: blur(1px);
        -moz-filter: blur(1px);
        -o-filter: blur(1px);
        -ms-filter: blur(1px);
        filter: blur(1px);
    }

    .locked {
        align-items: center;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        display: flex;
        font-size: 22px;
        font-weight: bold;
        height: 100%;
        line-height: 30px;
        justify-content: center;
        padding: 0 10px;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 2;
    }

    .blur-overlay {
        display: flex;
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 3;
    }

    .box {
        border-top-width: 10px;

        .box-header {
            .box-header-icon {
                color: #b5b5b5;
                position: absolute;
                right: 8px;
                top: 8px;
            }

            .description {
                max-width: 60%;
            }
        }

        .box-body {
            min-height: 269px;
        }

        .box-title {
            font-weight: bold;
            margin: 0 0 10px;
            padding-bottom: 5px;
            text-align: center;
        }
    }

    .box-content {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 220px;
    }

    .driver-info {
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid #cccccc;

        img {
            height: 50px;
        }

        span {
            padding: 0 15px;
            font-size: 22px;
            vertical-align: middle;
        }
    }

		.panel {
			.panel-title {
				a:hover {
					font-weight: bold;
					text-decoration: underline;
				}
				a:active, a:focus, a:hover {
					color: inherit !important;
				}
			}
		}
}

#check-animation {
    fill: none;
    stroke: green;
    stroke-width: 20;
    stroke-linecap: round;
    stroke-dasharray: 180;
    stroke-dashoffset: 180;
    animation: draw 0.6s;
    animation-fill-mode: forwards;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}