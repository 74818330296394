.checkbox {
	z-index: 1;
	margin-top: 5px;
	position: initial;
	
	label {
		cursor: pointer;
		padding-left: 0;
		padding-right: 10px;

		> div {
			margin-right: 5px;
		}
	}

	&.no-margin {
		margin: 0 !important;
	}
}