#pallet-load-list-table {    
	.cancelled {
			background: rgba(255, 0, 0, .07);
	}

	.in-progress {
			background: rgba(60, 141, 188, .07);
	}

	.completed {
			background: rgba(0, 144, 1, .07);
	}
}