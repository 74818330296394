.checkout-box {
  box-shadow: 5px 5px 2px rgba(0, 0, 0, 0.35);
}
.checkout-box.blur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}
.checkout-box .locked {
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  font-size: 22px;
  font-weight: bold;
  height: 100%;
  line-height: 30px;
  justify-content: center;
  padding: 0 10px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
}
.checkout-box .blur-overlay {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 3;
}
.checkout-box .box {
  border-top-width: 10px;
}
.checkout-box .box .box-header .box-header-icon {
  color: #b5b5b5;
  position: absolute;
  right: 8px;
  top: 8px;
}
.checkout-box .box .box-header .description {
  max-width: 60%;
}
.checkout-box .box .box-body {
  min-height: 269px;
}
.checkout-box .box .box-title {
  font-weight: bold;
  margin: 0 0 10px;
  padding-bottom: 5px;
  text-align: center;
}
.checkout-box .box-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 220px;
}
.checkout-box .driver-info {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #cccccc;
}
.checkout-box .driver-info img {
  height: 50px;
}
.checkout-box .driver-info span {
  padding: 0 15px;
  font-size: 22px;
  vertical-align: middle;
}
.checkout-box .panel .panel-title a:hover {
  font-weight: bold;
  text-decoration: underline;
}
.checkout-box .panel .panel-title a:active, .checkout-box .panel .panel-title a:focus, .checkout-box .panel .panel-title a:hover {
  color: inherit !important;
}

#check-animation {
  fill: none;
  stroke: green;
  stroke-width: 20;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: draw 0.6s;
  animation-fill-mode: forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

