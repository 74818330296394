.box-no-margin-bottom {
	margin-bottom: 0 !important;
}

.box, .nav-tabs-custom, .nav.nav-pills {
	box-shadow: 0px 1px 3px rgba(0,0,0,0.4) !important;
}

.box-bordered {
	box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.15) !important;
    border-left: 1px solid #bfbfbf;
}

.content-header > .breadcrumb {
	background: none !important;
	position: absolute !important;
    right: 10px !important;

	a {
		font-size: 22px;
	}
}

.sidebar-menu  {
	li > a {
		padding: 8px 5px 12px 15px;
	}

	> li > .treeview-menu {
		margin: 0 !important;
		background: #080b0c !important;
	}
}

@media (min-width: 768px) {
	.content-header > .breadcrumb {
		top: 8px !important;
	}
}

.navbar-nav > .user-menu {
	div {
		display: inline;
	}

	.dropdown-menu {
		box-shadow: -1px 3px 2px rgba(0, 0, 0, 0.7);
		right: 0 !important;
		border: none !important;

		> li {
			&.user-header img {
				z-index: 5;
				height: 90px;
				width: 90px;
				border: 3px solid;
				border-color: transparent;
				border-color: rgba(255,255,255,0.2);
			}

			&.user-footer {
				color: #fff;
				
				.btn-primary {
					background-color: #3c8dbc;
    				border-color: #367fa9;
				}

				.btn-danger {
					background-color: #a53f32;
					border-color: #bf493a;
				}
			}
		}
	}
}

/*
	TIMELINE
*/
.timeline {
	> li {
		> .timeline-item {
			background: none !important;
    		box-shadow: none !important;
			border: none !important;
			border-radius: 0 !important;
			border-left: 2px solid #848484 !important;

			> .timeline-header {
				border: none !important;
				
				.title {
					color: #2c76a0 !important;
					font-weight: bold;
				}
			}
			
			> .timeline-body {
				padding: 0 10px !important;
			}

			> .time {
				font-size: 16px !important;
    			font-weight: bold;
				float: none !important;
			}
		}
	}
}

.main-sidebar, .left-side {
	min-height: 0%;
}

@media (max-width: 767px) {
	.wrapper {
		height: auto;
		
		> .main-content, .main-sidebar {
			padding-top: 50px !important;
		}
	}
}

header.main-header {
	.logo {
		border-right: 1px solid #222d32;

		span {
			font-family: 'Source Sans Pro';
		}
	}
	
	.navbar, .logo  {
		background: #386eb9;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #386eb9, #0ea2c4);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #457cc9, #0ea2c4); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
}

.main-sidebar {
	background: #222d32;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to left, #2d3c42, #222d32);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to left, #2d3c42, #222d32); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	.sidebar-menu > li.header {
		font-size: 14px;
		color: white;
		background: #222d32;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to left, #2d3c42, #222d32);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to left, #1b2124, #111518); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
}

.callout {
	box-shadow: 1px 2px 3px rgba(0, 0, 0, .4);

	h4 {
		text-shadow: 2px 2px 2px rgba(0, 0, 0, .3);
	}

	&.callout-success {
		border-color: #05744d !important;
		background: #1D976C;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #1D976C, #29b35b);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #1D976C, #29b35b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
	
	&.callout-danger {
		border-color: #9e1509 !important;
		background: #e73827;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #e73827, #e73827);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #e73827, #f85032); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
	
	&.callout-info {
		border-color: #09738d !important;
		background: #1d8fac;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #17829c, #6dd5ed);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #17829c, #53b9cf); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
	
	&.callout-warning {
		border-color: #b1300c !important;
		background: #e75128;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to right, #e75128, #ff720b);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to right, #e75128, #ff720b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
}

.btn {
	text-shadow: 1px 1px 2px rgba(0, 0, 0, .15);
	// border: none !important;

	&:hover {
		filter: brightness(.9)	
	}

	&.btn-success {
		background: #1D976C;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #1D976C, #29b35b);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #1D976C, #29b35b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	&.btn-danger {
		background: #d83a2b;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #d83a2b, #ff7259);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #d83a2b, #ff7259); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
	
	&.btn-info {
		background: #17829c;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #17829c, #53b9cf);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #17829c, #53b9cf); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
	
	&.btn-warning {
		background: #F7971E;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #F7971E, #f5c800);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #F7971E, #f5c800); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	&.btn-primary {
		background: #1961a5;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #1961a5, #509ae0);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #1961a5, #509ae0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}

	&.btn-default {
		color: #525252;
		text-shadow: none;
		border: 1px solid #cccccc !important;
		background: #cacaca;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #cacaca, #ebebeb);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #dddddd, #eeeeee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	}
}