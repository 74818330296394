#staff-roster-list-responsive {
	.callout {
		height: 205px;

		> h1 {
			font-weight: normal;
			text-align: center;
			padding: 30px
		}
	}

	.table {
		margin-bottom: 0;
		th {
			width: 80px
		}
	}
}

@media only screen and (max-width : 767px) {
	#staff-roster-list-responsive {
		.callout-danger {
			height: 40px !important;
	
			> h1 {
				display: none;
			}
		}
	}
}