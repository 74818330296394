.full-map {
	.panel-body {
		padding: 5px 10px;
	}

	.info-box-main {
		z-index: 1; 
		position: absolute;
		left: 10px !important;
    	bottom: 5px !important;
		width: 220px;
		font-size: 11px;

		table tr th {
			width: 85px;
			padding: 1px 0;
		}

		.loader {
			position: absolute;
			bottom: 0;
			right: -70px;
		}
	}

	/** 
		INPUT FILTERS
	*/
	.input-filter-box {
		width: 100vw;
		max-width: 340px;
		margin: 10px;
		z-index: 1 !important;

		.search-input, .Select {
			width: 75%;
			font-size: 13px;
		}

		.search-type-box {
			position: absolute;
			height: 34px;
			padding: 8px 0;
			right: 0;
			top: 0px;
			width: 25%;
			background: #00c0ef;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			border-top: 1px solid #d2d6de;
			border-bottom: 1px solid #d2d6de;
			
			.fa {
				font-size: 16px;
				margin-left: 17px;
				color: white;
				opacity: .55;
				cursor: pointer;	

				&.active, &:hover {
					opacity: 1;
				}
			}
		}
	}

	/** 
		SETTINGS BUTTONS
	*/
	.settings-buttons-box {
		margin: 10px;

		button {
			color: #5a5a5a;
			margin-left: 10px;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
			background-color: white;
			font-size: 23px;
			padding: 5px 8px;
			border: none;
			border-radius: 2px;
			
			&.fade {
				opacity: .4;
			}

			&:hover {
				opacity: 1;
				color: #000;
				background-color: #ebebeb;
			}
		}
	}

	/** 
		RIGHT MENU
	*/
	.right-menu-box {
		top: 55px !important;
		right: 10px !important;
		width: 87vw;
		max-width: 320px;

		.form-group {
			> label {
				font-size: 13px;

				.fa {
					font-size: 15px;
				}
			}
		}
	}


	/** 
		INFO WINDOW
	*/
	.info-window-box {	
		margin-left: 10px;
		
		.info-window {
			width: 94vw;
			max-width: 340px;

			.close-button {
				position: absolute;
				right: 15px;
				top: 10px;
				cursor: pointer;
				color: red;
				font-size: 20px;

				&:hover {
					color: rgb(175, 0, 0);
				}
			}

			.tab-content {
				padding: 0 5px;

				.tab-pane {
					height: 28vh;
					overflow: auto;
				}
	
				table {
					font-size: 12px;
					
					th {
						width: 100px;
					}

					td, th {
						padding: 3px;
					}
				}
			}
	
			.input-sensors {
				width: 80px;
				padding-left: 3px;
				height: 20px;
				font-size: 13px;
			}

			.buttons-box {
				position: absolute;
				bottom: -17px;
				width: 100%;

				.btn {
					box-shadow: 2px 3px 0px rgba(0, 0, 0, 0.7);
					font-weight: bold;

					&.btn-default {
						background-color: #ffffff;

						&:hover {
							background-color: #f1f1f1;
						}
					}
				}
			}
		}
	}

	.vehicle-driver-details {
		padding: 5px 10px;
		margin-top: 10px;
		max-width: 90%;
		font-size: 13px;

		.driver-img {
			height: 50px;
		}
	}

	@media screen and (max-width: 767px) {
		.panel-body {
			padding: 8px 10px;
		}

		.info-box-main {
			width: 185px;
			font-size: 10px;
			

			table tr th {
				width: 70px;
			}
		}

		.input-filter-box {
			max-width: 291px;

			.search-type-box {
				.fa {
					margin-left: 13px;
				}
			}
		}
	}

	@media screen and (max-width: 400px) {
		.input-filter-box {
			max-width: 250px;

			.search-input, .Select {
				width: 70%;
			}
	
			.search-type-box {
				width: 30%;
			}
		}
	}

	@media screen and (max-width: 358px) {
		.input-filter-box {
			max-width: 230px;

			.search-input, .Select {
				width: 65%;
			}
	
			.search-type-box {
				width: 35%;

				.fa {
					margin-left: 16px;
				}
			}
		}

		.settings-buttons-box {	
			button {
				margin-left: 5px;
				font-size: 20px;
				padding: 3px 6px;
			}
		}

		.right-menu-box {
			top: 50px !important;
		}

		.info-window-box {				
			.info-window {
				.tab-content {
					.tab-pane {
						height: 15vh;
					}
				}
			}
		}
	}
}