#pallet-unload-list-table .cancelled, #pallet-unload-list-table .bg-red-light, #unload-pallets-list-table .cancelled, #unload-pallets-list-table .bg-red-light {
  background: rgba(255, 0, 0, 0.07);
}
#pallet-unload-list-table .in-progress, #pallet-unload-list-table .bg-blue-light, #unload-pallets-list-table .in-progress, #unload-pallets-list-table .bg-blue-light {
  background: rgba(60, 141, 188, 0.07);
}
#pallet-unload-list-table .completed, #pallet-unload-list-table .bg-green-light, #unload-pallets-list-table .completed, #unload-pallets-list-table .bg-green-light {
  background: rgba(0, 144, 1, 0.07);
}

