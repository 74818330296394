.main-sidebar .treeview > .treeview-menu {
	padding-left: 0 !important;
}

.main-sidebar  {
	a.disabled {
		cursor: not-allowed;
		pointer-events: none;
	}

	a.no-nested-item {
		background-color: #080b0c !important;
	}

	.treeview {
		ul li a {
			padding-left: 35px !important;		
		}
	}
	
	.treeview-menu  {
		a.disabled {
			color: #4f575a;
		}

		.treeview-menu {
			padding-left: 15px !important;
			background-color: rgb(8, 11, 12) !important;

			a.disabled {
				color: #2e3335;
			}
		}
	}
}