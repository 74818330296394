$bg-grey: rgba(179, 179, 179, 0.15);
$bg-red: rgba(255, 0, 0, 0.15);
$bg-light-red: rgba(255, 0, 0, 0.1);
$bg-blue: rgba(33, 133, 208, 0.20);
$bg-green: rgba(0, 160, 0, 0.12);

#task-modal {
	.badge {
		font-size: 16px;
		margin-top: -2px;

		&.cancelled {
			background-color: $bg-red;
		}

		&.in-progress {
			background-color: $bg-blue;
		}

		&.completed {
			background-color: $bg-green;
		}
	}
}

#roster-tasks-list {
	// .rt-thead {
	// 	border-right: 17px solid #ffffff
	// }

	.Select-menu-outer {
		position: relative;
	}
	
	.rt-tbody {
		.rt-tr {
			&.-even {
				background-color: rgb(250, 250, 250);
			}

			&.cancelled {
				background-color: $bg-light-red;
				
				// * {
				// 	text-decoration: line-through;
				// }
			}

			&.in-progress {
				background-color: $bg-blue;
			}

			&.completed {
				background-color: $bg-green;
			}

			.rt-td {
				font-size: 13px;
			}
		}
	}
}

.update-reason-box {
	.well,
	.form-control {
		border: 1px solid rgb(180, 180, 180);
	}

	.form-control {
		box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.4);
	}
}