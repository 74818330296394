#access_point_tab_container {
	.tab-content {
		padding-top: 15px;
	}

	#access_point_windows_tab {
		.nav-pills {
			box-shadow: none !important;
		}
	
		.box-header {
			border-bottom: 2px solid #e2e2e2;
		}
	}
}