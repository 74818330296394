#pallet-load-list-table .cancelled {
  background: rgba(255, 0, 0, 0.07);
}
#pallet-load-list-table .in-progress {
  background: rgba(60, 141, 188, 0.07);
}
#pallet-load-list-table .completed {
  background: rgba(0, 144, 1, 0.07);
}

