.ReactTable {
  background: #ffffff;

  .rt-thead {
    div {
      text-align: left;
      font-weight: bold;
    }

    .rt-th {
      border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .rt-tbody {
    .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.3) !important;
    }

    .rt-tr {
			&.line-through {
				text-decoration: line-through;
			}

      &.-even {
        background-color: rgb(250, 250, 250);
      }

      .orange,
      &.warning {
        background: #fff5e6 !important;
      }

      .red,
      &.danger {
        background: #ffe8e8 !important;
      }

      .blue,
      &.primary {
        background: #e2f5ff !important;
      }

      .grey,
      &.default {
        background: #e4e4e4 !important;
      }

      .green,
      &.success {
        background: #ebf9eb !important;
      }

      &:hover {
        cursor: pointer;
        .rt-td {
          font-weight: bold;
        }

        background-color: #e1f0ff;
        box-shadow: inset 0 2px 8px rgba(0, 128, 255, 0.12),
          inset 0 -2px 8px rgba(0, 128, 255, 0.12);
      }

      &:hover {
        .rt-td {
          &.bg-danger {
            filter: brightness(0.95);
            box-shadow: inset 0 2px 15px rgba(255, 0, 0, 0.1),
              inset 0 -2px 15px rgba(255, 0, 0, 0.1);
          }
        }
      }
    }

    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }

  .-pagination {
    .-btn {
      &:not(:disabled) {
        color: #fff;
        background: #367fa9;

        &:hover {
          background: #286090 !important;
        }
      }
    }
  }

  .orange {
    background: #fff5e6 !important;
  }

  .red {
    background: #ffe8e8 !important;
  }

  .blue {
    background: #e2f5ff !important;
  }

  .grey {
    background: #e4e4e4 !important;
  }

  .green {
    background: #ebf9eb !important;
  }
}
