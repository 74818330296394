.weekdays-time {
	table {
		td {
			cursor: pointer;

			&:hover {
				background-color: rgba(38, 144, 35, 0.20);
			}

			&.active {
				background-color: rgba(38, 144, 35, 0.60);
			}
		}
	}
}