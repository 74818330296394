$dangerColor: #bf493a;
$infoColor: #13aed4;
$successColor: #2d9063;


$bg-grey: rgba(179, 179, 179, 0.15);
$bg-red: rgba(255, 0, 0, 1);
$bg-red-light: rgba(255, 0, 0, 0.15);
$bg-blue-light: rgba(33, 133, 208, 0.2);
$bg-blue: rgba(33, 133, 208, 1);
$bg-green-light: rgba(0, 160, 0, 0.12);
$bg-green: rgba(0, 160, 0, 1);
$bg-orange-light: rgb(255, 188, 0, .2);
$bg-orange: rgba(255, 188, 0, 1);

.text-center {
    text-align: center !important;
}

.no-padding > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

[class*='col-'].no-padding-left {
    padding-left: 0;
}

[class*='col-'].no-padding-right {
    padding-right: 0;
}

.left-inner-addon,
.right-inner-addon {
	position: relative;

    i {
    	z-index: 4;
    	position: absolute;	
  		padding: 10px 13px;
  		pointer-events: none;
  		color: #ababab;
    }
}


.left-inner-addon {
    input {
    	padding-left: 30px;
    }
}

.right-inner-addon {
    input {
	    padding-right: 30px;    
	}

	i {
	    right: 0px;
	}
}

.input-group {
  .input-group-addon {
    background-color: #eee !important;

		&.separator {
			padding: 3px;
			border-left: none;
			border-right: none;
		}
  }

	.react-select-container {
		display: table-cell;
		position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
	}
}

.list-inline {
  > li {
    //float: left;
  }
}


/*
  BUTTONS
*/
.btn {
  &.btn-success {
    background-color: $successColor;
    border-color: $successColor;

    &:active {
      background-color: #216747;
    }

    &:hover {
      background-color: #277d56;
    }
  }

  &.btn-info {
    background-color: $infoColor;
    border-color: $infoColor;

    &:active {
      background-color: #25859c;
    }

    &:hover {
      background-color: #1ea1c1;
    }
  }

  &.btn-danger {
    background-color: $dangerColor;
    border-color: $dangerColor;

    &:active {
      background-color: #823026;
    }

    &:hover {
      background-color: #a53f32;
    }
  }

  &.btn-warning {
    &:hover {
      background-color: #d4860a;
    }
  }
}

/*
  END BUTTONS
*/


/*
  CALLOUT
*/
div.callout {
	padding: 10px;

  &.callout-danger {
    background-color: $dangerColor !important;
    border-color: #9c291b;
	}
}
/*
  END CALLOUT
*/


/*
  MODAL
*/
.modal {
  .modal-header {
    button.close {
      margin: 0;
      font-size: 38px;
      position: absolute;
      right: 18px;
      top: 5px;
      color: #bf4a3b;
      opacity: .7;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-lg {
    width: auto;
    margin: 10px;
    max-width: 1100px;

    @media (min-width: 1110px) {
      margin: 10px auto;
    }
  }
}
// .modal-dialog {
//     position: fixed;
//     top: 0;
//     right: 0;
//     left: 0;
//     z-index: 1050;
// }

// .modal-body {
//     overflow: auto;
//     max-height: 75vh;
// }
/*
  END MODAL
*/

@media only screen and (min-width : 768px) {
  // form {
  //   .row {
  //     display: flex;
  //     display: -webkit-flex;
  //   }
  // }

  // .aligned-row {
  //   display: flex;
  //   flex-flow: row wrap;

  //   &::before {
  //     display: block;
  //   }
  // }
}

.aligned-row {
  display: flex;
  flex-flow: row wrap;

  &::before {
    display: block;
  }
}

.row {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

/*
  DATATABLES
*/
div.dataTables_wrapper {
  .right {
    > div {
      float: right;
    }
  }

  .center {
    > div {
      text-align: center;
    }
  }

  .btn-group {
    > .btn:not(:first-child) {
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width : 768px) {
  div.dataTables_wrapper {
    .left, .right {
      text-align: center;
      padding: 10px 0;

      > div {
         float: inherit;
      }
    }
  }
}

.pac-container {
    z-index: 1051 !important;
}

.btn.align-middle {
  margin-top: 27px;
}

.nav.nav-pills.nav-justified {
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

}

@media (min-width: 1200px) and (max-width: 1699px) {
    .hidden-lg {
        display: none !important;
    }
}


.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block,
.visible-xl {
    display: none !important;
}


@media (min-width: 1700px) {
    .container {
        width: 1570px;
    }

    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-12 {
        width: 100%;
    }

    .col-xl-11 {
        width: 91.66666667%;
    }

    .col-xl-10 {
        width: 83.33333333%;
    }

    .col-xl-9 {
        width: 75%;
    }

    .col-xl-8 {
        width: 66.66666667%;
    }

    .col-xl-7 {
        width: 58.33333333%;
    }

    .col-xl-6 {
        width: 50%;
    }

    .col-xl-5 {
        width: 41.66666667%;
    }

    .col-xl-4 {
        width: 33.33333333%;
    }

    .col-xl-3 {
        width: 25%;
    }

    .col-xl-2 {
        width: 16.66666667%;
    }

    .col-xl-1 {
        width: 8.33333333%;
    }

    .col-xl-pull-12 {
        right: 100%;
    }

    .col-xl-pull-11 {
        right: 91.66666667%;
    }

    .col-xl-pull-10 {
        right: 83.33333333%;
    }

    .col-xl-pull-9 {
        right: 75%;
    }

    .col-xl-pull-8 {
        right: 66.66666667%;
    }

    .col-xl-pull-7 {
        right: 58.33333333%;
    }

    .col-xl-pull-6 {
        right: 50%;
    }

    .col-xl-pull-5 {
        right: 41.66666667%;
    }

    .col-xl-pull-4 {
        right: 33.33333333%;
    }

    .col-xl-pull-3 {
        right: 25%;
    }

    .col-xl-pull-2 {
        right: 16.66666667%;
    }

    .col-xl-pull-1 {
        right: 8.33333333%;
    }

    .col-xl-pull-0 {
        right: auto;
    }

    .col-xl-push-12 {
        left: 100%;
    }

    .col-xl-push-11 {
        left: 91.66666667%;
    }

    .col-xl-push-10 {
        left: 83.33333333%;
    }

    .col-xl-push-9 {
        left: 75%;
    }

    .col-xl-push-8 {
        left: 66.66666667%;
    }

    .col-xl-push-7 {
        left: 58.33333333%;
    }

    .col-xl-push-6 {
        left: 50%;
    }

    .col-xl-push-5 {
        left: 41.66666667%;
    }

    .col-xl-push-4 {
        left: 33.33333333%;
    }

    .col-xl-push-3 {
        left: 25%;
    }

    .col-xl-push-2 {
        left: 16.66666667%;
    }

    .col-xl-push-1 {
        left: 8.33333333%;
    }

    .col-xl-push-0 {
        left: auto;
    }

    .col-xl-offset-12 {
        margin-left: 100%;
    }

    .col-xl-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xl-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xl-offset-9 {
        margin-left: 75%;
    }

    .col-xl-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xl-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xl-offset-6 {
        margin-left: 50%;
    }

    .col-xl-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xl-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xl-offset-3 {
        margin-left: 25%;
    }

    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xl-offset-0 {
        margin-left: 0;
    }

    .visible-xl {
        display: block !important;
    }

    table.visible-xl {
        display: table;
    }

    tr.visible-xl {
        display: table-row !important;
    }

    th.visible-xl, td.visible-xl {
        display: table-cell !important;
    }

    .visible-xl-block {
        display: block !important;
    }

    .visible-xl-inline {
        display: inline !important;
    }

    .visible-xl-inline-block {
        display: inline-block !important;
    }

    .hidden-xl {
        display: none !important;
    }
}

@media print {
   .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        float: left;
   }
   .col-sm-12 {
        width: 100%;
   }
   .col-sm-11 {
        width: 91.66666667%;
   }
   .col-sm-10 {
        width: 83.33333333%;
   }
   .col-sm-9 {
        width: 75%;
   }
   .col-sm-8 {
        width: 66.66666667%;
   }
   .col-sm-7 {
        width: 58.33333333%;
   }
   .col-sm-6 {
        width: 50%;
   }
   .col-sm-5 {
        width: 41.66666667%;
   }
   .col-sm-4 {
        width: 33.33333333%;
   }
   .col-sm-3 {
        width: 25%;
   }
   .col-sm-2 {
        width: 16.66666667%;
   }
   .col-sm-1 {
        width: 8.33333333%;
	}

	body {
		overflow: auto !important;

		&.modal-open {
			#root {
				display: none !important;
			}
		}
	}

	button, .nav, .modal-backdrop, .modal-footer, 
	.no-print, .main-footer {
		display: none;
	}

	.tab-pane {
		opacity: 1 !important;
		display: block !important;
	}
	
	.modal {
		position: relative;
		background: white !important;
		padding-left: 0;

		> div {
			margin: 0;

			.modal-content {
				.modal-header, .modal-body {
					padding: 0;
				}
			}
	  }
	}
}

.nav-tabs {
	margin-bottom: 10px;
}

.table {
	td, th {
		vertical-align: middle !important;
	}
	
		&.table-black-border {
			td, th {
				border: 1px solid rgb(161, 161, 161) !important;
			}
	}
}

.page-header {
	padding-bottom: 0px;
	border-bottom: 2px solid #c7c7c7;

	h1 {
		font-size: 25px;
		font-weight: 600;
		margin-bottom: 5px;
		margin-top: 0;
	}
}

.badge {
	&.status {
		font-size: 16px;
		margin-top: -2px;

		&.danger {
			background-color: $bg-red-light;
		}
	
		&.primary {
			background-color: $bg-blue-light;
		}
	
		&.success {
			background-color: $bg-green-light;
		}

		&.warning {
			background-color: $bg-orange-light;
		}
	}

	&.danger {
		background-color: $bg-red;
	}

	&.primary {
		background-color: $bg-blue;
	}

	&.success {
		background-color: $bg-green;
	}

	&.warning {
		background-color: $bg-orange;
	}
}