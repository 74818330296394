$validColor: green;
$invalidColor: red;
$invalidColorForDarkBg: yellow;

.form-text {
	min-height: 34px;
}

.form-control-danger {
	background-color: #ffdbdb;
}


form {
	label {
		&.error, &.invalid {
			color: $invalidColor !important;
		}
	}
	
	.form-control, .Select-control, .signature-box {
		&.valid, &:focus.valid {
			border-color: $validColor;
		}

		&.error, &:focus.error, &.invalid, &.invalid:focus {
			border-color: $invalidColor !important;
		}
	}

	.callout-warning,
	.callout-danger {
		label {
			&.error, &.invalid {
				color: $invalidColorForDarkBg !important;
			}
		}
	}

	.form-block {
		> h3 {
			font-size: 18px;
			margin-top: 5px;
			margin-bottom: 0;
			color: #fff;
			padding: 4px 7px;
			background-color: #3d84ad;
			border-top-left-radius: 2px;
			border-top-right-radius: 2px;
		}

		.form-body {
			border-left: 1px solid #dadada;
			border-right: 1px solid #dadada;
			box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.15);
			margin-bottom: 20px;
			padding: 5px 5px 0;
		}
	}

	.media {
		margin-top: 0;

		div:nth-child(2) {
			padding-left: 15px;
		}

		.media-left {
			.fa {
				border: 1px solid #d6d6d6;
				border-radius: 10px;
				color: #d6d6d6;
				font-size: 50px;
				padding: 30px 30px;;
			}

			img {
				width: 107px;
				border-radius: 10px;
			}
		}
	}

	.no-image-crop {
		border: 1px solid #d0d0d0;
		border-radius: 10px;
		color: #a9a9a9;
		font-size: 60px;
		height: 100px;
		padding-top: 35px;
		padding-bottom: 109px;
		text-align: center;
		width: 100%;
	}
}

.signature-box {
	border: 1px solid #737373;
	touch-action: none !important;

	.clear-signature-btn {
		margin-top: 6px;
		position: absolute;
		right: 22px;
	}
}

.checkbox-custom {
	margin-right: 1em;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;

	&.radio-checked {
		font-weight: bold;
	}

	input[type="checkbox"] {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* For iOS < 15 */
		background-color: var(--form-background);
		/* Not removed via appearance */
		margin: 0;
	
		cursor: pointer;
		font: inherit;
		margin-right: 5px;
		color: #c7c7c7;
		width: 24px;
		height: 24px;
		border: 1px solid currentColor;
		transform: translateY(-0.075em);
		display: inline-grid;
		place-content: center;
	}
	
	input[type="checkbox"]::before {
		-webkit-text-fill-color: rgb(255, 255, 255); 
		color: white;
		-webkit-opacity: 1; 
		opacity: 1;
		font-family: 'FontAwesome';
		content: "\f00c";
		text-align: center;
		padding-top: 3px;
		font-size: 13px;
		width: 24px;
		height: 24px;
		transform: scale(0);
		box-shadow: inset 1em 1em var(--form-control-color);
		/* Windows High Contrast Mode */
		background-color: #2489c5;
	}
	
	input[type="checkbox"]:checked::before {
		transform: scale(1);
	}
	
	input[type="checkbox"]:focus, input[type="checkbox"]:hover {
		outline: none;
		border: 2px solid #2489c5;
	}
	
}