$xxxs: '360px';
$xxs: '430px';
$xs: '767px';
$sm: '991px';
$md: '1199px';

.ui-element {
	@media only screen and (max-width: $xxs) {
		&.dashboard-link-button {
			font-size: 15px;
			padding: 6px 10px;
		}
  }

	@media only screen and (max-width: $xxxs) {
		&.dashboard-link-button {
			font-size: 12px;
		}
  }
}

.connection-bar {
	z-index: 1;
	width: 100%;
	padding: 5px 20px;
	box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.4);
	
	font-size: 18px;
	font-weight: 500;
	text-align: center;

	transition: transform 1s ease-in-out, margin 1s ease-in-out;
}

.custom-icon {
	display: block;
}