.form-radio-flex {
	display: flex;

	.radio-list {
		margin-left: 10px;
	}
}

// .radio-list {
// 	&.radio-list-inline {
// 		display: flex;
// 	}

// 	.radio-input {
// 		margin-right: 20px;
// 	}
// }

// .radio-input {
// 	label {
// 		font-weight: normal;
// 		cursor: pointer;
// 	}
// }

.form-group {
	> .radio-list {
		margin-top: 5px;
	}
}

.radio-list {
	display: flex;
	text-align: center;

	&.radio-list-vertical {
		display: grid;
		text-align: left;
	}

}

.radio-custom {
	margin-right: 1em;
	vertical-align: middle;
	font-weight: normal;
	cursor: pointer;

	&.radio-checked {
		font-weight: bold;
	}

	input[type="radio"] {
		/* Add if not using autoprefixer */
		-webkit-appearance: none;
		/* Remove most all native input styles */
		appearance: none;
		/* For iOS < 15 */
		background-color: var(--form-background);
		/* Not removed via appearance */
		margin: 0;
	
		cursor: pointer;
		font: inherit;
		margin-right: 3px;
		color: #c7c7c7;
		width: 24px;
		height: 24px;
		border: 1px solid currentColor;
		border-radius: 50%;
		transform: translateY(-0.075em);
		display: inline-grid;
		place-content: center;
	}
	
	input[type="radio"]::before {
		-webkit-text-fill-color: rgb(255, 255, 255); 
		color: white;
		-webkit-opacity: 1; 
		opacity: 1;
		font-family: 'FontAwesome';
		content: "\f00c";
		text-align: center;
		padding-top: 2px;
		font-size: 14px;
		width: 24px;
		height: 24px;
		border-radius: 50%;
		transform: scale(0);
		box-shadow: inset 1em 1em var(--form-control-color);
		/* Windows High Contrast Mode */
		background-color: #2489c5;
	}
	
	input[type="radio"]:checked::before {
		transform: scale(1);
	}
	
	input[type="radio"]:focus, input[type="radio"]:hover {
		outline: none;
		border: 2px solid #2489c5;
	}
}