#pallet-load-panel {	
	.panel-row-container {
		position: relative;
		border-radius: 5px;
		box-shadow: 1px 4px 3px rgba(0, 0, 0, .5);
		margin-top: 12px;

		&.bg-red {
			background-color: #614040 !important;
		}

		&.bg-yellow {
			background-color: #6f5a2d !important;
		}

		&.bg-blue {
			background-color: #303b52 !important;
		}

		&.bg-green {
			background-color: #406142 !important;
		}

		.panel-row {
			z-index: 10;
			padding: 10px;

			span:not(.status-icon) {
				font-weight: bold;
				font-size: 28px;
				margin: 0;
				z-index: 1;
				vertical-align: middle;
			}			

			.status-icon {
				position: absolute;
				font-size: 45px;
				color: black;
				right: 10px;
				top: 7px;
				opacity: .4;
				text-shadow: none;
				z-index: 3;
			}
		}

		&.small {
			.panel-row {
				padding: 5px 10px;

				span:not(.status-icon) {
					font-size: 19px;
				}
	
				.status-icon {
					font-size: 35px;
				}
			}
		}
	}

	.loads-panel-body {
		.panel-row-container {
			text-shadow: 2px 2px 3px rgba(0, 0, 0, .7);
		}
	}

	.loads-panel-header {
		background-color: #292929;
		color: white;
	}

	tbody {
		td {
			font-weight: bold;
			padding: 20px 15px;
			font-size: 40px;
			border-top-width: 10px;
		}
	}

	.progress-bar {
		position: absolute;
		border-radius: 5px;
		top: 0;
		left: 0;
		height: 100%;
	}

	.divider {
		margin-top: 10px;
		font-size: 50px;
		text-shadow: none !important;
		width: 100%;
		text-align: center;
		color: green;
	}

	.callout {
		margin-top: 15px;
		margin-bottom: 0;
    text-align: center;
		padding: 20px;

		h4 {
			font-size: 25px;
			margin: 0;
		}
	}
}