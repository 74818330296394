.worker-time-sheet-container input::-webkit-clear-button, .worker-time-sheet-container input::-webkit-calendar-picker-indicator, .worker-time-sheet-container input::-webkit-inner-spin-button {
  display: none; /* Hide the button */
  -webkit-appearance: none; /* turn off default browser styling */
}
.worker-time-sheet-container .column-title {
  color: #ffffff;
  background: #909090;
  text-align: center !important;
  border-right: 2px solid #6f6f6f !important;
}
.worker-time-sheet-container .split-line {
  border-right: 2px solid #bfbfbf !important;
}
.worker-time-sheet-container .edit {
  cursor: pointer;
}
.worker-time-sheet-container .edit:hover {
  opacity: 0.7;
}
.worker-time-sheet-container .rt-tbody .rt-tr .rt-td {
  white-space: unset;
}

