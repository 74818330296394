.time-windows {
	table {
		.table-action-button {
			width: 80px;
		}

		thead {
			tr {
				th, td {
					border-bottom-width: 2px !important;
				}
			}
		}

		tr {
			.th-week-day {
				width: 50px;
				vertical-align: middle;
			}
	
			th, td {
				border: 1px solid #bdbdbd !important;
			}

			&.even {
				background-color: #f4fffc;
			}

			&.odd {
				background-color: #f4fcff;
			}

			&.saturday {
				background-color: #fffbf1;
			}

			&.sunday {
				background-color: #fff6e5;
			}

			&:hover {
				filter: brightness(95%)
			}
		}
	}
}