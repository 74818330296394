$blue-color: #337ab7;

.nav-pills {
	li {

		&.active {
			a, a:hover {
				color: white;
				background-color: $blue-color;
			}
		}

		a:hover {
			filter: brightness(95%);
		}
	}
}