.pre-start__main {
    &.first-time-tour {
        .joyride-tooltip__close {
            display: none !important;
        }
    }

    &:not(.first-time-tour) {
        .joyride-tooltip__button--skip {
            color: green !important;
            text-decoration: underline !important;
        }
    }
    
    .pre-start__fuelLevel {
        .radio-list .radio-input {
            margin-right: 15px;
        }
    }
}

.bg-light-amber {
	background-color: #fff9ef;
}