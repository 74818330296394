#pallet-unload-list-table, #unload-pallets-list-table {    
	.cancelled, .bg-red-light {
			background: rgba(255, 0, 0, .07);
	}

	.in-progress, .bg-blue-light {
			background: rgba(60, 141, 188, .07);
	}

	.completed, .bg-green-light {
			background: rgba(0, 144, 1, .07);
	}
}