$bg-danger: rgba(255, 0, 0, .1);
$bg-info: rgba(33, 133, 208, .1);
$bg-success: rgba(0, 128, 0, .1);
$bg-warning: rgba(255, 230, 0, .1);

#task-modal {
	.badge {
		&.cancelled {
			background-color: $bg-danger;
		}

		&.in-progress {
			background-color: $bg-info;
		}

		&.done {
			background-color: $bg-success;
		}
	}
}

#staff-roster-list {
	// .rt-thead {
	// 	border-right: 17px solid #ffffff
	// }

	.Select-menu-outer {
		position: relative;
	}
	
	.rt-tbody {
		.rt-tr {
			&.-even {
				background-color: rgb(250, 250, 250);
			}

			&.bg-danger {
				background-color: $bg-danger;
				color: #ad2522;
			}

			&.bg-info {
				background-color: $bg-info;
			}

			&.bg-warning {
				background-color: $bg-warning;
				color: #a57a02;
			}

			&.bg-success {
				background-color: $bg-success;
			}

			&.bg-warning,
			&.bg-danger {
				font-weight: bold;
			}

			.rt-td {
				font-size: 13px;
			}
		}
	}
}

.update-reason-box {
	.well,
	.form-control {
		border: 1px solid rgb(180, 180, 180);
	}

	.form-control {
		box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.4);
	}
}