/* Absolute Center Spinner */
.overlay {
  position: fixed;
  z-index: 9999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .loader {
    margin: -45px -25px;
  }
}

/* Transparent Overlay */
.overlay:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.overlay:not(:required) {
  /* hide "overlay..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loader {
  width: 55px;
  height: 55px;
  margin: 0 auto;
  border: 5px solid #2e6f94;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 800ms infinite linear;
  -o-animation: cssload-spin 800ms infinite linear;
  -ms-animation: cssload-spin 800ms infinite linear;
  -webkit-animation: cssload-spin 800ms infinite linear;
  -moz-animation: cssload-spin 800ms infinite linear;
  
  &.loader-white {
    border-color: #ffffff;
    border-left-color: transparent;
    border-right-color: transparent;
  }

	&.loader-sm {
		width: 18px;
		height: 18px;
		border-width: 3px;
	}

	&.loader-large {
	width: 80px;
	height: 80px;
	border-width: 6px;
	}
}

.btn {
  .loader {
    width: 14px;
    height: 14px;
    border-width: 2px;
    display: inline-flex;
    margin: -2px 2px;
  }
}


@keyframes cssload-spin {
  100%{ transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
  100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes cssload-spin {
  100%{ -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
  100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
  100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}