.pre-start-list-table .popover-content {
  overflow: auto;
  max-height: 200px;
}
.pre-start-list-table .pending-approval {
  background: rgba(230, 147, 14, 0.07);
}
.pre-start-list-table .not-approved {
  background: rgba(255, 0, 0, 0.07);
}
.pre-start-list-table .run-in-progress {
  background: rgba(60, 141, 188, 0.07);
}
.pre-start-list-table .last-part-sent {
  background: rgba(0, 0, 0, 0.07);
}
.pre-start-list-table .finalized {
  background: rgba(0, 144, 1, 0.07);
}

